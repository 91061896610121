<template>
  <ErrorPageWrapper
    title="ERROR_PAGE.500.TITLE"
    content="ERROR_PAGE.500.CONTENT"
    errorCode="500"
    routeName="Home"
    buttonLabel="ERROR_PAGE.500.BTN"
  />
</template>

<script>
import ErrorPageWrapper from "@components/ErrorPage/ErrorPageWrapper.vue";

export default {
  name: "ServerError",

  components: {
    ErrorPageWrapper,
  },
};
</script>
